import store from '@/store'
import httpRequest from '@/global/http'

import variables from '@/assets/scss/modules/_variables.scss'

/**
 * 檢查上傳檔案是否符合限制
 * @param {FileList} files 使用者上傳的檔案
 * @param {Array} validFileTypes 檔案限制格式
 * @param {Number} perFileLimitSize 每個檔案限制大小(byte)
 * @param {Number} maxFilesCount 檔案限制數量
 * @returns {String} 檔案檢查結果, 若檢查通過則回傳空字串, 若有錯誤則回傳錯誤訊息
 */
function validateFiles(files, validFileTypes, perFileLimitSize, maxFilesCount = 1) {
    let isValidFileType = true, isNonEmptyFile = true, isValidFileSize = true, isValidFileCount = true;
    let errorMsg = "";

    for (const file of files) {
        const tempFileExtType = file.name.split('.').pop().toLowerCase();

        if (!validFileTypes.includes(tempFileExtType)) {
            isValidFileType = false;
            break;
        }
        if (file.size === 0) {
            isNonEmptyFile = false;
            break;
        }
        if (file.size > perFileLimitSize) {
            isValidFileSize = false;
            break;
        }
    }
    if (files.length > maxFilesCount) {
        isValidFileCount = false;
    }

    let msg = "";
    if (!isValidFileType) {
        msg = "檔案格式不符，請重新上傳";
    }
    if (!isNonEmptyFile) {  // 空檔案
        msg = "檔案大小錯誤，請重新上傳";
    }
    if (!isValidFileSize) {
        msg = "檔案大小超過限制，請重新上傳";
    }
    if (!isValidFileCount) {
        msg = `檔案數量超過限制${maxFilesCount}個，請重新上傳`;
    }
    errorMsg = ((!isValidFileType || !isValidFileSize) && files.length > 1) ? "部份" + msg : msg;

    return errorMsg;
}

/**
 * 轉換 dataURL 為檔案
 * @param     string  dataURL   欲被轉換成檔案的dataURL
 * @param     string  filename  輸出的檔案名稱
 * @param     string  mimeType  輸出的檔案格式
 *
 * @return    file  檔案
 */
function dataURLToFile(dataURL, filename, mimeType) {
    return (fetch(dataURL)
        .then(res => { return res.arrayBuffer() })
        .then(buf => { return new File([buf], filename, {type: mimeType}) })
    );
}

/**
 * 下載檔案
 * @param     string  filePath  檔案完整路徑
 * @param     string  fileName  下載後的檔案名稱
 */
function downloadFile(filePath, fileName) {
    return new Promise((resolve, reject) => {
        if (!filePath) {
            reject(`Download File Error: filePath is empty`);
            return;
        }

        fetch(filePath)
            .then(response => {
                if (response.ok) {
                    return response.blob();
                }

                reject(`Download File Error: Load "${response.url}" failed`);
            })
            .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);

                resolve();
            })
            .catch(error => {
                reject(`Download File Error: ${error}`);
                store.dispatch('common/setAlert', { msg: '系統繁忙中，請稍後再試', status: 'danger' });
            });
    });
}

/**
 * 轉換 unixTimestamp 為 datetime
 * [e.g.] input: 1620185479 -> output: 2021/05/05 11:31:19
 * @param     integer|string  unixTimestamp  時間戳
 * @param     string          locale         時區
 * @param     string          formattedType  轉換格式 (datetime | date)
 * @param     boolean         isHideSecond   是否隱藏秒數
 *
 * @return    string  datetime格式時間
 */
function unixTimestampToDatetime(unixTimestamp, locale, formattedType = 'datetime', isHideSecond = false) {
    if (!unixTimestamp) {
        return '';
    }

    let result = '';

    unixTimestamp = +unixTimestamp;
    let milliseconds = unixTimestamp * 1000;
    let dateObject = new Date(milliseconds);

    let options = {};
    if (formattedType === 'date') {
        options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }
    } else {
        options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            hourCycle: 'h23',
            minute: '2-digit'
        }
        if (!isHideSecond) {
            options.second = '2-digit';
        }
    }

    result = dateObject.toLocaleString(locale, options);

    return result;
}

/**
 * 轉換 datetime 為 unixTimestamp
 * [e.g.] input: 2021/05/05 11:31:19 -> output: 1620185479
 * @param     string  datetime  日期與時間
 *
 * @return    integer  unixTimestamp
 */
function datetimeToUnixTimestamp(datetime) {
    if (!datetime) {
        return 0;
    }

    let result = 0;

    result = Math.floor(+new Date(datetime) / 1000);

    return result;
}

/**
 * 取得瀏覽器目前時區
 *
 * @return    string  時區
 */
function getBrowserCurrentTimeZone() {
    let language = null;

    if (window.navigator.languages) {
        language = window.navigator.languages[0];
    } else {
        language = window.navigator.userLanguage || window.navigator.language;
    }

    return language;
}

/**
 * 將秒數格式化為自己選定的格式類型
 * @param     string  data           秒數
 * @param     string  formattedType  結果格式(mm:ss | m:ss | m:s)
 *
 * @return    string  mm:ss 格式化結果
 */
function formatSecToTimeText(data, formattedType, fullwidth = true) {
    let sec = +data;

    let interval = sec / 60;
    let minutes = Math.floor(interval);
    let seconds = sec % 60;

    let formattedMinutes, formattedSeconds;
    switch (formattedType) {
        case 'mm:ss':
            formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
            break;
        case 'm:ss':
            formattedMinutes = minutes;
            formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
            break;
        case 'm:s':
            formattedMinutes = minutes;
            formattedSeconds = seconds;
            break;
    }

    let result = `${formattedMinutes}${fullwidth ? '：' : ':'}${formattedSeconds}`;

    return result;
}

/**
 * 在網頁 <body> 元件下新增遮罩
 */
function addBodyBackdrop() {
    const n1 = document.createElement('div');
    n1.id = 'bodyBackdrop';
    document.body.appendChild(n1);
    document.body.style.overflow = 'hidden';
}

/**
 * 在網頁 <body> 元件下移除遮罩
 */
function removeBodyBackdrop() {
    const e1 = document.getElementById('bodyBackdrop');
    e1 && document.body.removeChild(e1);
    document.body.style.overflow = '';
    $('.modal').modal('hide');  // 關閉所有彈窗
}

/**
 * 設定 <input type="text"> 輸入框, 輸入內容時讓字體大小適應輸入框寬度
 * @param     string  inputDOM
 * @param     number  fontSize    字體設定大小
 * @param     number  fontWeight  字體設定粗細
 */
function shrinkFontSizeToFillInputWidth(inputDOM, fontSize, fontWeight) {
    // 預測文字的總寬度
    let measureText = (text, fontSize, fontWeight, fontFamily) => {
        var id = 'text-width-tester',
            $tag = $('#' + id);
        if (!$tag.length) {
            $tag = $(`<span id="${id}" style="display: none; font-size: ${fontSize}px; font-weight: ${fontWeight}; font-family: ${fontFamily};">${text}</span>`);
            $('body').append($tag);
        } else {
            $tag.css({ fontSize: fontSize, fontWeight: fontWeight, fontFamily: fontFamily }).html(text);
        }
        return {
            width: $tag.width(),
            height: $tag.height()
        }
    }

    let $input = $(inputDOM),
        text = $input.val(),
        maxWidth = Math.floor($input.width());
    let fontFamily = variables.fontfamilymain;
    let textWidth = Math.floor(measureText(text, fontSize, fontWeight, fontFamily).width);

    // 如果預測文字總寬度已超過輸入框寬度, 則讓文字大小隨著字數愈多而愈變愈小
    if (textWidth > maxWidth) {
        let newFontSize = fontSize * maxWidth / textWidth;
        $input.css({ fontSize: newFontSize, fontWeight: fontWeight, fontFamily: fontFamily });
    } else {
        $input.css({ fontSize: fontSize, fontWeight: fontWeight, fontFamily: fontFamily });
    }
}

/**
 * 新增行為 log
 * @param     object  payload  log格式
 */
function addLog(payload) {
    httpRequest.get('/api/log/write_log', payload)
        .then(() => {})
        .catch(error => {
            console.log('catched error: ' + error);
        });
}

/**
 * 更新meta data
 * @param     string  title        meta title
 * @param     string  description  meta description
 * @param     string  imageUrl     og:image
 */
function updateMeta(title, description, imageUrl) {
    // <head>
    const elemTitle = document.querySelector('head title');
    elemTitle.textContent = title || process.env.VUE_APP_TITLE;
    const elemDescription = document.querySelector('head meta[name="description"]');
    elemDescription.setAttribute('content', description || process.env.VUE_APP_DESCRIPTION);

    // og tag
    const ogTitle = document.querySelector('head meta[property="og:title"]');
    ogTitle.setAttribute('content', title || process.env.VUE_APP_TITLE);
    const ogDescription = document.querySelector('head meta[property="og:description"]');
    ogDescription.setAttribute('content', description || process.env.VUE_APP_DESCRIPTION);
    const ogImage = document.querySelector('head meta[property="og:image"]');
    ogImage.setAttribute('content', imageUrl || process.env.VUE_APP_HOST + process.env.VUE_APP_OG_IMAGE);
}


const methods = {
    validateFiles,
    dataURLToFile,
    downloadFile,

    unixTimestampToDatetime,
    datetimeToUnixTimestamp,
    getBrowserCurrentTimeZone,
    formatSecToTimeText,

    addBodyBackdrop,
    removeBodyBackdrop,
    shrinkFontSizeToFillInputWidth,

    addLog,
    updateMeta,
}

export default methods
